import styled from 'styled-components'
import { Col } from '@semcore/ui/grid'
import { MOBILE_BREAKPOINT } from 'helpers/constants'
import WavesUrl from 'assets/waves-background.svg?url'
import WavesMobileUrl from 'assets/waves-background-mobile.svg?url'
import BlueNoiseUrl from 'assets/blue-background.png?url'
import { ContentWrapperStyles } from '../content-wrapper'

export const Container = styled.div`
  background-image: url(${WavesUrl}), url(${BlueNoiseUrl});
  background-repeat: no-repeat, repeat;
  background-position:
    0 102%,
    center;
  background-size: contain, auto;

  padding: 11% 0;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    padding: 10% 0;
    background-image: url(${WavesMobileUrl}), url(${BlueNoiseUrl});
    background-position:
      0 108%,
      center;
  }

  ${ContentWrapperStyles};

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    background-position:
      0 113%,
      center;
  }
`

export const Stories = styled(Col)`
  & > &:first-child {
    margin-top: 9%;
  }
  & > :not(:last-child) {
    margin-bottom: 8%;

    @media (max-width: ${MOBILE_BREAKPOINT}px) {
      margin-bottom: 11%;
    }
  }
`

import { useInView } from 'react-intersection-observer'
import { useEffect, useState } from 'react'

export const useWasBlockVisible = (): {
  wasVisibleBlock: boolean
  ref: (node?: Element | null) => void
} => {
  const { ref, inView } = useInView({
    threshold: 0.2,
  })
  const [wasVisibleBlock, setWasVisibleBlock] = useState<boolean>(inView)

  useEffect(() => {
    if (inView) {
      setWasVisibleBlock(true)
    }
  }, [inView])

  return {
    wasVisibleBlock,
    ref,
  }
}

import { TWITTER_LINK, LINKEDIN_LINK, FACEBOOK_LINK, TELEGRAM_LINK } from 'constants/links'

interface Link {
  title: string
  link: string
}

export const Links: Link[] = [
    { title: 'Linkedin', link: LINKEDIN_LINK },
    { title: 'X (Twitter)', link: TWITTER_LINK },
    { title: 'Facebook', link: FACEBOOK_LINK }, 
  { title: 'Telegram', link: TELEGRAM_LINK },
]

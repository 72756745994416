import React from 'react'
import { Col, Row } from '@semcore/ui/grid'
import { Flex } from '@semcore/ui/flex-box'

import { CONTACTS_BLOCK, EMAIL } from 'constants/links'
import LinkArrow from 'assets/link_arrow.svg'
import { ContactLink, Container, EmailText, LinksContainer, MainLink, ProfileImage } from './styled'
import { Links } from './constants'

const Footer: React.FC = () => {
  return (
    <Container id={CONTACTS_BLOCK}>
      <Row gutter={7.5} justifyContent="center">
        <Col span={10} sm={12}>
          <Flex position="relative">
            <MainLink href={`mailto:${EMAIL}`} target="_blank" rel="noreferrer noopener">
              <EmailText>{EMAIL}</EmailText>
              <LinkArrow />
            </MainLink>
            <ProfileImage />
          </Flex>

          <LinksContainer>
            {Links.map(({ link, title }) => (
              <Col span={3} sm={6} key={title}>
                <ContactLink href={link} target="_blank" rel="noreferrer noopener">
                  {title}
                </ContactLink>
              </Col>
            ))}
          </LinksContainer>
        </Col>
      </Row>
    </Container>
  )
}

export default React.memo(Footer)
